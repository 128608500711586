import React, { useContext, useEffect, useState } from "react";
import BodyHeader from "../../components/BodyHeader/BodyHeaderProfile/BodyHeaderProfile";
import styles from "./ContractPage.module.scss";
import { Button, Divider } from "@mui/material";
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf.svg"; // Импорт SVG-иконки как компонента
import { ReactComponent as WordIcon } from "../../assets/icons/word.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { IoMdClose } from "react-icons/io";
import MainModal from "../../components/MainModal/MainModal";
import InfoComponent from "../../components/InfoComponent/InfoComponent";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import { contractAPI } from "../../api/contractAPI";
import { useDispatch, useSelector } from "react-redux";
import ModalsContext from "../../contexts/ModalsContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  addContracts,
  setContract,
  setContracts,
  setContractStatus,
  updateContracts,
} from "../../redux/slices/contractSlice";
import BodyHeaderContracts from "../../components/BodyHeader/BodyHeaderContracts/BodyHeaderContracts";
import { ThreeCircles } from "react-loader-spinner";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";

const options = [
  {
    label: "Статус",
    type: "Chip",
    name: "Status",
    text: "На проверке у сотрудника",
  },
  {
    label: "Полное наименование",
    name: "TextCompany",
    type: "Text",
    text: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ 'ИНТЕРНЕШНЛ ЛОДЖИСТИКС КОНСАЛТИНГ'",
  },
  {
    label: "Комментарий менеджера",
    name: "TextManagerComment",
    type: "Text",
    text: "Некоторый комментарий менеджера",
  },
  {
    label: "Номер контракта",
    name: "TextContractNumber",
    type: "Text",
    text: "34234234234",
  },
  {
    label: "Внешнеэкономический контракт на учете?",
    name: "is_vbk",
    type: "Chip",
    text: "Да",
  },
  {
    label: "Комментарий клиента",
    name: "ClientComment",
    type: "TextField",
    text: "Некоторый комментарий клиента",
  },
  {
    label: "Файлы",
    name: "Files",
    type: "Card",
    text: "",
  },
  {
    label: "Другие файлы",
    name: "OtherFiles",
    type: "Button",
    text: "Выбрать файлы",
  },
];

const newContractOptions = [
  {
    label: "Карточка компании плательщика",
    type: "Text",
    text: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ 'ИНТЕРНЕШНЛ ЛОДЖИСТИКС КОНСАЛТИНГ'",
    name: "full_name",
  },
  {
    label: "Статус",
    type: "Chip",
    text: "Черновик",
    statusColor: "Gray",
    name: "",
  },
  {
    label: "Номер контракта",
    type: "TextField",
    text: "Введите номер контракта",
    name: "contract_number",
  },
  {
    label: "Внешнеэкономический контракт на учете?",
    type: "Checkbox",
    text: "",
    name: "is_vbk",
  },
  {
    label: "Внешнеэкономический контракт",
    type: "Button",
    text: "Выберите внешнеэкономический контракт",
    name: "contract",
  },
  {
    label: "ВБК",
    type: "Button",
    text: "Выберите ВБК",
    name: "vbk",
  },
  {
    label: "ДТ1",
    type: "Button",
    text: "Выберите ДТ 1",
    name: "dt1",
  },
  {
    label: "ДТ2",
    type: "Button",
    text: "Выберите ДТ 2",
    name: "dt2",
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
    name: "comment_client",
  },
];

const fileIcons = {
  pdf: <PdfIcon width="25px" height="25px" />,
  word: <WordIcon width="25px" height="25px" />,
  excel: <ExcelIcon width="25px" height="25px" />,
  image: <ImageIcon width="25px" height="25px" />,
  unknown: <FileIcon width="25px" height="25px" />,
};

const ContractPage = () => {
  const [newContractData, setNewContractData] = useState({
    contract_number: "",
    contract_status: 2,
    comment_client: "",
    agent: null,
    is_vbk: false,
  });

  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingNew, setLoadingNew] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState({});

  const [statuses, setStatuses] = useState([]);
  const [errMessage, setErrorMessage] = useState([]);
  const [errContract, setErrContract] = useState([]);

  const [clientComment, setClientComment] = useState("");

  const contracts = useSelector((state) => state.contract.contracts);
  const contract = useSelector((state) => state.contract.contract);
  const authData = useSelector((state) => state.auth.authData);
  const company = useSelector((state) => state.agent.company);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const contractStatus = useSelector((state) => state.contract.contractStatus);

  const dispatch = useDispatch();

  const filteredOptions = contract.is_vbk
    ? options
    : options.filter((option) => option.name !== "vbk");

  const {
    newContractModalOpen,
    handleContractModalOpen,
    handleContractModalClose,
  } = useContext(ModalsContext);

  useEffect(() => {
    console.log("isAuth", isAuth);
    setLoading(true);
    if (isAuth && company) {
      (async () => {
        try {
          // fetching contracts
          const response = await contractAPI.get_contracts(
            authData,
            company.id
          );
          if (response.statusText === "OK") {
            if (response.data.length === 0) {
              handleContractModalOpen();
            }
            dispatch(setContracts(response.data));
            // fetching statuses
            const dataStatuses = await contractAPI.get__contract_statuses(
              authData
            );
            if (dataStatuses.statusText === "OK") {
              setStatuses(dataStatuses.data);
              if (response.data.length > 0) {
                if (!contract) {
                  const firstContract = response.data[0];
                  dispatch(setContract(firstContract));
                  setClientComment(firstContract.comment_client);
                  const foundStatus = dataStatuses.data.find(
                    (status) => status.id === firstContract.contract_status
                  );
                  if (foundStatus) {
                    dispatch(setContractStatus(foundStatus));
                  }
                } else {
                  const foundStatus = dataStatuses.data.find(
                    (status) => status.id === contract.contract_status
                  );
                  if (foundStatus) {
                    dispatch(setContractStatus(foundStatus));
                  }
                  setClientComment(contract.comment_client);
                }
              }
            }
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, company, contract]);

  const handleChangeInputs = (event, field) => {
    let value;
    if (field === "is_vbk") {
      value = event.target.checked;
    } else value = event.target.value;

    setNewContractData({
      ...newContractData,
      [field]: value,
    });
  };

  const handleChangeComment = (event) => {
    const text = event.target.value;
    setClientComment(text);
  };

  const handleFileChange = async (event, fileType, fileDocType) => {
    const file = event.target.files[0];

    let docTypes;

    let foundDocType;

    try {
      const response = await contractAPI.get_contract_doc_types(authData);
      if (response.statusText === "OK") {
        docTypes = response.data;
        foundDocType = docTypes.find((item) => item.type_title === fileDocType);
      }
    } catch (err) {
      console.log(err);
    }
    setSelectedFiles({
      ...selectedFiles,
      [fileType]: { file: file, docType: foundDocType },
    });
  };

  const handleFileUpload = async (event) => {
    setFileLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();

    if (file) {
      setErrContract([]);

      try {
        formData.append("contr_file", file);
        formData.append("contract", contract.id);
        formData.append("file_name", file.name);
        formData.append("file_type", file.type);
        formData.append("doc_type", 11);

        const response = await contractAPI.postfile(authData, formData);
        if (response.statusText === "Created") {
          const updatedContract = {
            ...contract,
            files: [...contract.files, response.data],
          };
          dispatch(updateContracts(updatedContract));
          dispatch(setContract(updatedContract));
        }
        setFileLoading(false);
      } catch (err) {
        console.log("Ошибка при загрузке файла:", err);
        const messageArr = [];
        messageArr.push("Ошибка при загрузке файла");
        setErrContract(messageArr);
      }
    }
  };

  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleChangeContract = (event) => {
    const selectedId = event.target.value;
    const selectedContract = contracts.find(
      (contract) => contract.id === selectedId
    );
    dispatch(setContract(selectedContract));

    setClientComment(selectedContract.comment_client);

    const foundStatus = statuses.find(
      (status) => status.id === selectedContract.contract_status
    );
    if (foundStatus) {
      dispatch(setContractStatus(foundStatus));
    }
  };

  const handleCreateContract = async () => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });

    const filesArray = [];

    for (const key of Object.keys(selectedFiles)) {
      const file = selectedFiles[key].file;
      const docType = selectedFiles[key].docType;

      if (file) {
        const base64File = await toBase64(file);
        const filesObj = {
          file_base64: base64File,
          file_name: file.name,
          file_type: file.type,
          doc_type: docType.id,
        };
        filesArray.push(filesObj);
      }
    }

    const dataToDB = {
      ...newContractData,
      files: filesArray,
      agent: company.id,
    };

    setErrorMessage([]);

    if (!selectedFiles.dt1 || !selectedFiles.dt2) {
      const newErrors = [];

      if (!selectedFiles.dt1) {
        newErrors.push("Не выбран ДТ1");
      }

      if (!selectedFiles.dt2) {
        newErrors.push("Не выбран ДТ2");
      }
      setErrorMessage(newErrors);
    } else {
      try {
        setLoadingNew(true);
        const data = await contractAPI.create_contracts(authData, dataToDB);

        if (data.statusText === "Created") {
          const newContract = data.data;
          dispatch(addContracts(newContract));
          handleContractModalClose();
          dispatch(setContract(newContract));
          setSelectedFiles({});
        }
        setLoadingNew(false);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleUpdateContract = async () => {
    setLoading(true);
    try {
      const data = await contractAPI.update__contract(
        clientComment,
        authData,
        contract.id
      );
      if (data.statusText === "OK") {
        const updatedContract = data.data;
        dispatch(setContract(updatedContract));
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <BodyHeaderContracts
        chosenOption={contract}
        onChangeFunc={handleChangeContract}
        list={contracts}
        handleOpen={handleContractModalOpen}
        text="Выберите контракт"
        buttonText="Новый контракт"
        contract={contract}
        loading={loading}
      />
      {loading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(28, 107, 31, 0.7)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : contracts.length > 0 ? (
        <InfoComponent
          text={`Контракт № ${contract.contract_number}`}
          options={filteredOptions}
          fileIcons={fileIcons}
          contractStatus={contractStatus}
          company={company}
          contract={contract}
          clientComment={clientComment}
          handleChangeComment={handleChangeComment}
          triggerFileInput={triggerFileInput}
          handleFileUpload={handleFileUpload}
          fileLoading={fileLoading}
        />
      ) : (
        <div className={styles.warnText}>
          <AssignmentIcon className={styles.rotatingIcon} />
          <span>Добавьте новый контракт</span>
        </div>
      )}
      <MainModal
        open={newContractModalOpen}
        onClose={handleContractModalClose}
        isContract
      >
        <div className={styles.modalheader}>
          <h2>Новый контракт</h2>
          <div onClick={handleContractModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal
          options={newContractOptions}
          newContract
          selectedFiles={selectedFiles}
          handleFileChange={handleFileChange}
          handleChangeInputs={handleChangeInputs}
          triggerFileInput={triggerFileInput}
          newContractData={newContractData}
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(38, 45, 137, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(38, 45, 137, 0.7)",
              },
            }}
            onClick={handleCreateContract}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
        {errMessage &&
          errMessage.map((err, index) => (
            <div className={styles.err} key={index}>
              {err}
            </div>
          ))}
      </MainModal>
      {contracts.length > 0 && (
        <div className={styles.btnContainer}>
          {errContract &&
            errContract.map((err, index) => (
              <div className={styles.err} key={index}>
                {err}
              </div>
            ))}
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(28, 107, 31, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(28, 107, 31, 0.8)",
              },
            }}
            onClick={handleUpdateContract}
          >
            {loading ? <DotsSpinner /> : "Обновить"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContractPage;
