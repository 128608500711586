import styles from "./HomePage.module.scss";
import { useContext, useEffect, useState } from "react";
import BodyHeader from "../../components/BodyHeader/BodyHeaderProfile/BodyHeaderProfile";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import MainModal from "../../components/MainModal/MainModal";
import { Button, Divider } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { agentAPI } from "../../api/agentAPI";
import { useDispatch, useSelector } from "react-redux";
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone";
import ModalsContext from "../../contexts/ModalsContext";
import {
  addProfiles,
  setCompany,
  setProfileStatus,
  setProfiles,
  updateProfiles,
} from "../../redux/slices/agentSlice";
import {
  removeContract,
  removeContractStatus,
} from "../../redux/slices/contractSlice";
import { ThreeCircles } from "react-loader-spinner";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";

const options = [
  {
    name: "ИНН компании",
    placeholder: "7712345678",
    label: "inn_company",
    type: "text",
  },
  {
    name: "Полное наименование",
    placeholder: "Общество с ограниченной ответственностью 'Весна'",
    label: "full_name",
    type: "text",
  },
  {
    name: "Юридический адрес",
    placeholder: "123456, г.Москва, ул. Подвойского, д.14, стр.7",
    label: "address_company",
    type: "text",
  },
  {
    name: "Почтовый адрес",
    placeholder: "123456, г.Москва, ул. Подвойского, д.14, стр.7",
    label: "mail_address_company",
    type: "text",
  },
  {
    name: "КПП компании",
    placeholder: "779101001",
    label: "kpp_company",
    type: "text",
  },
  {
    name: "ОКВЭД (основной)",
    placeholder: "18.15",
    label: "okved_company",
    type: "text",
  },
  {
    name: "ОГРН компании",
    placeholder: "1047712345678",
    label: "ogrn_company",
    type: "text",
  },
  {
    name: "БИК банка",
    placeholder: "044521234",
    label: "bik_company",
    type: "text",
  },
  {
    name: "Наименование банка",
    placeholder: "Московский банк ПАО Сбербанк г.Москва",
    label: "name_bank",
    type: "text",
  },
  {
    name: "Р/С клиента в банке",
    placeholder: "40702810123450101230",
    label: "check_company",
    type: "text",
  },
  {
    name: "К/С банка",
    placeholder: "30101234500000000225",
    label: "korrespondent_company",
    type: "text",
  },
  {
    name: "ОКПО компании",
    placeholder: "12345678",
    label: "okpo_company",
    type: "text",
  },
  {
    name: "ОКАТО компании",
    placeholder: "87654321000",
    label: "okato_company",
    type: "text",
  },
  {
    name: "Генеральный директор",
    placeholder: "Петров Сергей Сергеевич",
    label: "director_company",
    type: "text",
  },
  {
    name: "Электронная почта",
    placeholder: "info@vesna.ru",
    label: "email_company",
    type: "text",
  },
  {
    name: "Телефон",
    placeholder: "+749512345678",
    label: "phone_company",
    type: "text",
  },
  {
    name: "Сайт",
    placeholder: "www.vesna.ru",
    label: "site_company",
    type: "text",
  },
  { name: "Комиссия", placeholder: "", label: "comission", type: "text" },
  {
    name: "Комментарий по согласованной комиссии",
    placeholder: "",
    label: "comment",
    type: "text",
  },
];

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);

  const [checkCreate, setCheckCreate] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [managersCreate, setManagersCreate] = useState({
    manager_1: null,
    manager_2: null,
  });

  const [checkUpdate, setCheckUpdate] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [managersUpdate, setManagersUpdate] = useState({
    manager_1: null,
    manager_2: null,
  });

  const [managers_ilc, setManagers_ilc] = useState([]);
  const [managers_ilc_tp, setManagers_ilc_tp] = useState([]);

  const [statuses, setStatuses] = useState([]);

  const [formData, setFormData] = useState({
    full_name: "",
    address_company: "",
    mail_address_company: "",
    inn_company: "",
    kpp_company: "",
    okpo_company: "",
    okato_company: "",
    ogrn_company: "",
    okved_company: "",
    bik_company: "",
    name_bank: "",
    check_company: "",
    korrespondent_company: "",
    director_company: "",
    email_company: "",
    phone_company: "",
    site_company: "",
    comission: 5,
    comment: "",
  });

  const profiles = useSelector((state) => state.agent.profiles);
  const profileStatus = useSelector((state) => state.agent.profileStatus);
  const authData = useSelector((state) => state.auth.authData);
  const company = useSelector((state) => state.agent.company);
  const isAuth = useSelector((state) => state.auth.isAuth);

  const dispatch = useDispatch();

  const {
    newProfileModalOpen,
    handleProfileModalOpen,
    handleProfileModalClose,
  } = useContext(ModalsContext);

  useEffect(() => {
    console.log("isAuth", isAuth);
    console.log("profileStatus", profileStatus);

    setLoading(true);
    if (isAuth) {
      (async () => {
        try {
          // fetching profiles
          const response = await agentAPI.get_agent_profiles(authData);
          if (response.statusText === "OK") {
            if (response.data.length === 0) {
              handleProfileModalOpen();
            }
            dispatch(setProfiles(response.data));

            // fetching statuses
            const dataStatuses = await agentAPI.get_statuses(authData);
            if (dataStatuses.statusText === "OK") {
              setStatuses(dataStatuses.data);
              if (response.data.length > 0) {
                if (!company) {
                  const firstCompany = response.data[0];
                  dispatch(setCompany(firstCompany));
                  const foundStatus = dataStatuses.data.find(
                    (status) => status.id === firstCompany.pay_profile_status
                  );
                  console.log("foundStatus", foundStatus);

                  if (foundStatus) {
                    dispatch(setProfileStatus(foundStatus));
                  }
                } else {
                  const foundStatus = dataStatuses.data.find(
                    (status) => status.id === company.pay_profile_status
                  );
                  if (foundStatus) {
                    dispatch(setProfileStatus(foundStatus));
                  }
                }
              }
            }

            // fetching managers
            const dataManagers = await agentAPI.get_managers(authData);
            if (dataManagers.statusText === "OK") {
              const managerIlc = dataManagers.data.filter(
                (item) => item.company === 1
              );
              const managerIlcTp = dataManagers.data.filter(
                (item) => item.company === 2
              );
              setManagers_ilc(managerIlc);
              setManagers_ilc_tp(managerIlcTp);
            }
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (!checkCreate.firstCheck) {
      setManagersCreate((prev) => ({ ...prev, manager_1: null }));
    } else if (!checkCreate.secondCheck) {
      setManagersCreate((prev) => ({ ...prev, manager_2: null }));
    }
  }, [checkCreate]);

  useEffect(() => {
    if (!checkUpdate.firstCheck) {
      setCheckUpdate((prev) => ({ ...prev, manager_1: null }));
    } else if (!checkUpdate.secondCheck) {
      setCheckUpdate((prev) => ({ ...prev, manager_2: null }));
    }
  }, [checkUpdate]);

  const handleChangeCompany = async (event) => {
    const selectedId = event.target.value;
    const selectedCompany = profiles.find(
      (profile) => profile.id === selectedId
    );
    dispatch(setCompany(selectedCompany));
    dispatch(removeContract());

    const foundStatus = statuses.find(
      (status) => status.id === selectedCompany.pay_profile_status
    );
    if (foundStatus) {
      dispatch(setProfileStatus(foundStatus));
    }

    dispatch(removeContractStatus());

    setCheckUpdate({
      firstCheck: selectedCompany.work_ilc,
      secondCheck: selectedCompany.work_ilc_tp,
    });
    setManagersUpdate({
      manager_1: selectedCompany.manager_ilc,
      manager_2: selectedCompany.manager_tp,
    });
  };

  const handleChangeManager = (event, managerKey, isModal) => {
    console.log(event.target.value);

    isModal
      ? setManagersCreate((prev) => ({
          ...prev,
          [managerKey]: event.target.value,
        }))
      : setManagersUpdate((prev) => ({
          ...prev,
          [managerKey]: event.target.value,
        }));
  };

  const handleChangeCheck = (event, isModal) => {
    console.log(event.target.checked);
    const check = event.target.checked;

    if (isModal) {
      setCheckCreate((prev) => ({
        ...prev,
        [event.target.name]: check,
      }));
      if (check)
        setFormData((prev) => ({ ...prev, comission: prev.comission - 0.25 }));
      else
        setFormData((prev) => ({ ...prev, comission: prev.comission + 0.25 }));
    } else {
      setCheckUpdate((prev) => ({
        ...prev,
        [event.target.name]: check,
      }));
    }
  };

  const handleFillFields = (event, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };

  const handleChangeFields = (event, fieldName) => {
    dispatch(
      setCompany({
        ...company,
        [fieldName]: event.target.value,
      })
    );
  };

  const handleCreateProfile = async () => {
    setLoadingNew(true);

    let dataToDB = formData;
    dataToDB = {
      ...formData,
      pay_profile_status: 1,
      work_ilc: checkCreate.firstCheck,
      manager_ilc: managersCreate.manager_1,
      work_ilc_tp: checkCreate.secondCheck,
      manager_tp: managersCreate.manager_2,
      //agent_success: true,
    };
    console.log("dataToDB", dataToDB);
    try {
      const data = await agentAPI.create_agent_profile(dataToDB, authData);

      if (data.statusText === "Created") {
        const newCompany = data.data;
        dispatch(addProfiles(newCompany));
        handleProfileModalClose();
      }
      setLoadingNew(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    let dataToDB = company;
    dataToDB = {
      ...company,
      pay_profile_status: 1,
      work_ilc: checkUpdate.firstCheck,
      manager_ilc: managersUpdate.manager_1,
      work_ilc_tp: checkUpdate.secondCheck,
      manager_tp: managersUpdate.manager_2,
      // agent_success: true,
    };
    try {
      const data = await agentAPI.update_agent_profile(dataToDB, authData);
      if (data.statusText === "OK") {
        const updatedCompany = data.data;
        dispatch(updateProfiles(updatedCompany));
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <BodyHeader
        chosenOption={company}
        onChangeFunc={handleChangeCompany}
        list={profiles.length > 0 ? profiles : null}
        handleOpen={handleProfileModalOpen}
        text="Выберите компанию"
        buttonText="Новая компания"
        profileStatus={profileStatus}
        loading={loading}
      />
      {loading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(33, 150, 243, 0.9)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : profiles.length > 0 ? (
        <ProfileInfo
          options={options}
          managers_1={managers_ilc}
          managers_2={managers_ilc_tp}
          check={checkUpdate}
          managers={managersUpdate}
          data={company}
          handleChangeManager={handleChangeManager}
          handleChangeCheck={handleChangeCheck}
          handleChangeFields={handleChangeFields}
          loading={loading}
        />
      ) : (
        <div className={styles.warnText}>
          <WorkTwoToneIcon className={styles.rotatingIcon} />
          <span>Добавьте новую компанию</span>
        </div>
      )}

      <MainModal open={newProfileModalOpen} onClose={handleProfileModalClose}>
        <div className={styles.modalheader}>
          <h2>Профиль компании</h2>
          <div onClick={handleProfileModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>

        <Divider />
        <ProfileInfo
          options={options}
          managers_1={managers_ilc}
          managers_2={managers_ilc_tp}
          check={checkCreate}
          managers={managersCreate}
          data={formData}
          handleChangeManager={handleChangeManager}
          handleChangeCheck={handleChangeCheck}
          handleChangeFields={handleFillFields}
          isModal
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
            }}
            onClick={handleCreateProfile}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
      </MainModal>

      {profiles.length > 0 && (
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
            }}
            onClick={handleUpdateProfile}
          >
            {loading ? <DotsSpinner /> : "Обновить"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomePage;
