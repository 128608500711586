import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profiles: [],
  company: "",
  profileStatus: null,
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    removeProfiles: (state, action) => {
      state.profiles = [];
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    removeCompany: (state, action) => {
      state.company = "";
    },
    addProfiles: (state, action) => {
      state.profiles = [...state.profiles, action.payload];
    },
    updateProfiles: (state, action) => {
      state.profiles = state.profiles.map((profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
    },
    setProfileStatus: (state, action) => {
      state.profileStatus = action.payload;
    },
    removeProfileStatus: (state, action) => {
      state.profileStatus = null;
    },
  },
});

export const {
  setProfiles,
  removeProfiles,
  setCompany,
  removeCompany,
  addProfiles,
  updateProfiles,
  setProfileStatus,
  removeProfileStatus,
} = agentSlice.actions;

export default agentSlice.reducer;
