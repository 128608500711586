import styles from "./App.module.scss";
import LoginPage from "./Pages/LoginPage/LoginPage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import HomePage from "./Pages/HomePage/HomePage";
import PrivateRoute from "./components/Routes/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ContractPage from "./Pages/ContractPage/ContractPage";
import RequestsPage from "./Pages/RequestsPage/RequestsPage";
import { useMediaQuery } from "react-responsive";
import NavBar from "./components/NavBar/NavBar";
import { useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Cookies from "js-cookie";
import { setAuthData, setIsAuth } from "./redux/slices/authSlice";
import EmailForResetPage from "./Pages/EmailForResetPage/EmailForResetPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage/ResetPasswordPage";
import { contractAPI } from "./api/contractAPI";
import { setDocTypes } from "./redux/slices/contractSlice";

function App() {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const isAuth = useSelector((state) => state.auth.isAuth);

  const tabletMediaQuery = useMediaQuery({ maxWidth: 800 });
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getAuthData = () => {
    const authDataCookie = Cookies.get("authData");
    let authData = null;
    if (authDataCookie) {
      try {
        authData = JSON.parse(authDataCookie);
      } catch (error) {
        console.error("Error parsing authData cookie:", error);
      }
    }

    return authData;
  };

  useEffect(() => {
    const currentAuthData = getAuthData();
    const currentPath = location.pathname;

    if (!currentAuthData && currentPath !== "/reset-password/confirm") {
      if (isFirstRender) {
        navigate("/auth");
      }
    } else if (currentAuthData) {
      dispatch(setAuthData(currentAuthData));
      dispatch(setIsAuth(true));
    }
    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.app}>
      {isAuth && <Header />}

      <div className={styles.main}>
        {isAuth && !tabletMediaQuery && <Sidebar />}
        {isAuth && tabletMediaQuery && <NavBar />}
        <ScrollToTop />
        <div className={styles.content}>
          <Routes>
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/reset-password" element={<EmailForResetPage />} />
            <Route
              path="/reset-password/confirm"
              element={<ResetPasswordPage />}
            />

            <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
            <Route
              path="/contracts"
              element={<PrivateRoute element={<ContractPage />} />}
            />
            <Route
              path="/requests"
              element={<PrivateRoute element={<RequestsPage />} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
