import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { CiLogout } from "react-icons/ci";
import { setIsAuth } from "../../redux/slices/authSlice";
import { authAPI } from "../../api/authAPI";
import Cookies from "js-cookie";
import {
  removeCompany,
  removeProfiles,
  removeProfileStatus,
} from "../../redux/slices/agentSlice";
import { removeContractStatus } from "../../redux/slices/contractSlice";

const Header = () => {
  const dispatch = useDispatch();

  const authData = useSelector((state) => state.auth.authData);

  const handleLogout = async () => {
    console.log("authData", authData);

    try {
      const response = await authAPI.logout(authData);

      if (response.status === 205) {
        Cookies.remove("authData");
        dispatch(setIsAuth(false));
        dispatch(removeProfiles());
        dispatch(removeCompany());
        dispatch(removeProfileStatus());
        dispatch(removeContractStatus());
      } else {
        console.log(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <header className={styles.header}>
      <button onClick={handleLogout}>
        <CiLogout className={styles.logout} />
      </button>
      <div className={styles.text}>Платежный агент ILC</div>
      <div className={styles.text}>Имя Пользователя</div>
    </header>
  );
};

export default Header;
