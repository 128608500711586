import RequestsTable from "../../components/RequestsTable/RequestsTable";
import styles from "./RequestsPage.module.scss";
import { useState } from "react";
import Request from "./Request/Request";

import { ReactComponent as PdfIcon } from "../../assets/icons/pdf.svg"; // Импорт SVG-иконки как компонента
import { ReactComponent as WordIcon } from "../../assets/icons/word.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import MainModal from "../../components/MainModal/MainModal";
import { IoMdClose } from "react-icons/io";
import { Button, Divider } from "@mui/material";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import { useNavigate } from "react-router-dom";

const headers = [
  { name: "Номер заявки", key: "id", type: "Text" },
  { name: "Дата заявки", key: "requestDate", type: "Text" },
  {
    name: "Статус заявки",
    key: "requestStatus",
    type: "Chip",
  },
  { name: "Сумма", key: "total", type: "TextFieldNumber" },
  { name: "Валюта", key: "currency", type: "Select" },
  { name: "Согласованный курс", key: "agreedRate", type: "Text" },
];

const rows = [
  {
    id: 1,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 100000,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 2,
    requestDate: "28.07.2024",
    requestStatus: "Заявка подписана",
    total: 3000,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 3,
    requestDate: "28.07.2024",
    requestStatus: "Курс согласован",
    total: 2000,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 4,
    requestDate: "28.07.2024",
    requestStatus: "Запрос курса",
    total: 430943,
    currency: "USD",
    agreedRate: "89",
  },
  {
    id: 5,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 24938298,
    currency: "RUB",
    agreedRate: "89",
  },
  {
    id: 6,
    requestDate: "28.07.2024",
    requestStatus: "Заявка подписана",
    total: 1000,
    currency: "CNY",
    agreedRate: "89",
  },
  {
    id: 7,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 8,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 9,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
  {
    id: 10,
    requestDate: "28.07.2024",
    requestStatus: "Счет на оплату",
    total: 49999,
    currency: "EUR",
    agreedRate: "89",
  },
];

const contract = "839848239489834849849";
const payer =
  "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ 'ИНТЕРНЕШНЛ ЛОДЖИСТИКС КОНСАЛТИНГ";

const files = [
  {
    name: "ДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdfДТ1_Шаблон_заявки.pdf",
    type: "pdf",
  },
  { name: "ВБК_Саратов_Москва (3).pdf", type: "pdf" },
  { name: "ДТ1_Шаблон_заявки.doc", type: "word" },
  { name: "ДТ1_Шаблон_заявки.xlsx", type: "excel" },
  { name: "Шаблон_заявки.jpg", type: "image" },
  { name: "Агентский_отчет.txt", type: "unknown" },
];

const fileIcons = {
  pdf: <PdfIcon width="25px" height="25px" />,
  word: <WordIcon width="25px" height="25px" />,
  excel: <ExcelIcon width="25px" height="25px" />,
  image: <ImageIcon width="25px" height="25px" />,
  unknown: <FileIcon width="25px" height="25px" />,
};

const newContractOptions = [
  {
    label: "Контракт",
    type: "Text",
    text: "839848239489834849849'",
  },
  {
    label: "Статус заявки",
    type: "Chip",
    text: "Запрос курса",
    statusColor: "Green",
  },
  {
    label: "Сумма",
    type: "TextField",
    text: "Введите сумму",
  },
  {
    label: "Валюта",
    type: "Select",
    text: "RUB",
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
  },
];

const RequestsPage = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <div className={styles.container}>
      {!selectedRow && (
        <div className={styles.headerText}>
          <div className={styles.text}>Плательщик</div>
          <div className={styles.payer}>{payer}</div>
          <h3>Список заявок по контракту № {contract} </h3>
        </div>
      )}

      <div className={styles.buttonContainer}>
        {selectedRow && (
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              // minWidth: "140px",
              minHeight: "40px",
            }}
            onClick={() => setSelectedRow(null)}
          >
            Вернуться к заявкам
          </Button>
        )}
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{
            // minWidth: "140px",
            minHeight: "40px",
          }}
          onClick={handleOpen}
        >
          Новая заявка
        </Button>
      </div>

      {!selectedRow && (
        <RequestsTable
          headers={headers}
          contract={contract}
          payer={payer}
          rows={rows}
          handleRowClick={handleRowClick}
        />
      )}

      {selectedRow && (
        <Request
          text="Заявка"
          headers={headers}
          item={selectedRow}
          setSelectedRow={setSelectedRow}
          files={files}
          fileIcons={fileIcons}
          contract={contract}
          handleOpen={handleOpen}
        />
      )}

      <MainModal open={modalOpen} onClose={handleClose}>
        <div className={styles.modalheader}>
          <h2>Новая заявка</h2>
          <div onClick={handleClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal options={newContractOptions} />
      </MainModal>
    </div>
  );
};

export default RequestsPage;
