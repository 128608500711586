import styles from "./BodyHeaderProfile.module.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReusableChip from "../../ReusableChip/ReusableChip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BodyHeaderProfile = ({
  chosenOption,
  onChangeFunc,
  list,
  handleOpen,
  text,
  buttonText,
  profileStatus,
  loading,
}) => {
  const getStatusStyles = (statusId) => {
    switch (statusId) {
      case 1:
        return {
          borderColor: "rgba(33, 150, 243, 0.5)",
          backgroundColor: "rgba(33, 150, 243, 0.1)",
          color: "rgba(0, 81, 148, 0.8)",
        };
      case 2:
        return {
          borderColor: "rgba(255, 165, 0, 0.5)",
          backgroundColor: "rgba(255, 165, 0, 0.1)",
          color: "rgba(255, 165, 0, 0.8)",
        };
      case 3:
        return {
          borderColor: "rgba(5, 172, 133, 0.5)",
          backgroundColor: "rgba(5, 172, 133, 0.1)",
          color: "rgba(5, 172, 133, 0.8)",
        };
      default:
        return null;
    }
  };
  return (
    <div className={styles.header}>
      <div className={styles.h2}>
        <h2>{text}</h2>
      </div>
      <FormControl
        sx={{ m: 1, width: 600, minWidth: 200 }}
        disabled={(!list || loading) && true}
      >
        <InputLabel>{text}</InputLabel>
        <Select
          value={chosenOption?.id || ""}
          onChange={onChangeFunc}
          input={<OutlinedInput label="Выберите компанию" />}
          MenuProps={MenuProps}
        >
          {list &&
            list.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.full_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {!loading &&
        list &&
        profileStatus &&
        (() => {
          const styles = getStatusStyles(profileStatus.id);
          if (!styles) return null;
          return (
            <ReusableChip
              borderColor={styles.borderColor}
              backgroundColor={styles.backgroundColor}
              color={styles.color}
              label={profileStatus.status_title}
              minHeight="50px"
              minWidth="none"
            />
          );
        })()}

      <Button
        variant="contained"
        endIcon={<AddIcon />}
        sx={{
          minWidth: "140px",
          minHeight: "50px",
        }}
        onClick={handleOpen}
        disabled={loading}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default BodyHeaderProfile;
