import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  authData: {
    refresh: "",
    access: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
      // console.log("state.isAuth", state.isAuth);
    },
    setAuthData: (state, action) => {
      state.authData = action.payload;
    },
  },
});

export const { setIsAuth, setAuthData } = authSlice.actions;

export default authSlice.reducer;
