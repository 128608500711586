import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

export const getSidebarOptions = (profiles, profileStatus, contractStatus) => [
  {
    label: "Profile",
    text: "Профили компаний",
    icon: <RecentActorsRoundedIcon />,
    disabled: false,
  },
  {
    label: "Contracts",
    text: "Контракты",
    icon: <StickyNote2RoundedIcon />,
    disabled: profiles.length > 0 && profileStatus?.id === 3 ? false : true,
  },
  {
    label: "Requests",
    text: "Заявки",
    icon: <EditNoteRoundedIcon />,
    disabled:
      profiles.length > 0 && profileStatus?.id === 3 && contractStatus?.id === 7
        ? false
        : true,
  },
];
