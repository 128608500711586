import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contracts: [],
  contract: "",
  contractStatus: null,
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    removeContracts: (state, action) => {
      state.contracts = [];
    },
    setContract: (state, action) => {
      state.contract = action.payload;
    },
    removeContract: (state, action) => {
      state.contract = "";
    },
    addContracts: (state, action) => {
      state.contracts = [...state.contracts, action.payload];
    },
    updateContracts: (state, action) => {
      state.contracts = state.contracts.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
    },
    setContractStatus: (state, action) => {
      state.contractStatus = action.payload;
    },
    removeContractStatus: (state, action) => {
      state.contractStatus = null;
    },
  },
});

export const {
  setContracts,
  removeContracts,
  setContract,
  removeContract,
  addContracts,
  updateContracts,
  setContractStatus,
  removeContractStatus,
} = contractSlice.actions;

export default contractSlice.reducer;
