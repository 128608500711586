import ReactInputMask from "react-input-mask";
import styles from "./RegistrationPage.module.scss";
import { imgURL } from "../../config";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData, setIsAuth } from "../../redux/slices/authSlice";
import { authAPI } from "../../api/authAPI";
import { useState } from "react";
import Cookies from "js-cookie";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";

const RegistrationPage = () => {
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    phone_number: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.isAuth);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value.replace(/\D/g, "");
    setUserData((prevData) => ({
      ...prevData,
      phone_number: phoneNumber,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await authAPI.register(userData);
      if (response.statusText === "Created") {
        const data = await authAPI.login(userData.email, userData.password);
        if (data.statusText === "OK") {
          const authData = data.data;
          Cookies.set("authData", JSON.stringify(authData), { secure: true });
          dispatch(setIsAuth(true));
          dispatch(setAuthData(authData));
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.imgContainer}>
          <img alt="logo" src={imgURL} />
        </div>
        <div className={styles.content}>
          <h1>Платежный агент</h1>
          <div className={styles.form}>
            <div className={styles.inputContainer}>
              <span className={styles.spanText}>Регистрация</span>
              <input
                className={styles.input}
                placeholder="Имя"
                name="first_name"
                value={userData.first_name}
                onChange={(e) => handleChangeData(e)}
              />
              <input
                className={styles.input}
                placeholder="Фамилия"
                name="last_name"
                value={userData.last_name}
                onChange={(e) => handleChangeData(e)}
              />
              <input
                className={styles.input}
                placeholder="Пароль"
                name="password"
                type="password"
                value={userData.password}
                onChange={(e) => handleChangeData(e)}
              />
              <input
                className={styles.input}
                placeholder="Email"
                name="email"
                type="email"
                value={userData.email}
                onChange={(e) => handleChangeData(e)}
              />

              <ReactInputMask
                mask="+7 (999) 999-99-99"
                maskChar={null}
                name="phone_number"
                value={userData.phone_number}
                className={styles.input}
                placeholder="Телефон"
                onChange={(e) => handlePhoneChange(e)}
              />

              <button
                className={styles.button}
                type="submit"
                aria-label="Нажмите, чтобы зарегистрироваться"
              >
                {loading ? (
                  <DotsSpinner auth />
                ) : (
                  <span className={styles.spanText2}>Зарегистрироваться</span>
                )}
              </button>
              <div className={styles.textLink}>
                <button onClick={() => navigate("/auth")} type="button">
                  Уже зарегистрирован
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationPage;
