import PageLoader from "../Loaders/PageLoader/PageLoader";
import styles from "./ProfileInfo.module.scss";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const ProfileInfo = ({
  options,
  check,
  managers,
  managers_1,
  managers_2,
  data,
  handleChangeManager,
  handleChangeCheck,
  handleChangeFields,
  isModal,
  loading,
}) => {
  const { firstCheck, secondCheck } = check;
  const { manager_1, manager_2 } = managers;

  // if (loading) {
  //   return <PageLoader />;
  // }

  return (
    <div className={styles.body}>
      {options.map((option, index) => (
        <div className={styles.element} key={index}>
          <div className={styles.name}>
            <span>{option.name}</span>
          </div>
          {option.label !== "comission" && (
            <div className={styles.field}>
              <TextField
                label={option.name}
                placeholder={option.placeholder}
                color="warning"
                sx={{
                  width: "100%",
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Для Firefox
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none", // Для Chrome, Safari, Edge, Opera
                      margin: 0,
                    },
                }}
                value={data ? data[option.label] : ""}
                onChange={(e) => handleChangeFields(e, option.label)}
                type={option.type}
                InputLabelProps={{
                  shrink: !!data[option.label],
                }}
              />
            </div>
          )}
          {option.label === "comission" && (
            <div className={styles.fieldText}>{data[option.label]}</div>
          )}
        </div>
      ))}

      <div className={styles.checkboxElement}>
        <div className={styles.name}>
          <span>Работаете с ИЛС</span>
        </div>
        <div className={styles.field}>
          <Checkbox
            color="warning"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            checked={firstCheck || false}
            onChange={(e) => handleChangeCheck(e, isModal)}
            name="firstCheck"
          />
        </div>
      </div>
      {firstCheck && (
        <CheckBoxOption
          handleChangeManager={handleChangeManager}
          manager={manager_1}
          managers={managers_1}
          text="Менеджер ИЛС"
          managerKey="manager_1"
          isModal={isModal}
        />
      )}
      <div className={styles.checkboxElement}>
        <div className={styles.name}>
          <span>Работаете с ИЛС ТП</span>
        </div>
        <div className={styles.field}>
          <Checkbox
            color="warning"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            checked={secondCheck || false}
            name="secondCheck"
            onChange={(e) => handleChangeCheck(e, isModal)}
          />
        </div>
      </div>
      {secondCheck && (
        <CheckBoxOption
          handleChangeManager={handleChangeManager}
          manager={manager_2}
          managers={managers_2}
          text="Менеджер ИЛС ТО"
          managerKey="manager_2"
          isModal={isModal}
        />
      )}
    </div>
  );
};

const CheckBoxOption = ({
  handleChangeManager,
  manager,
  managers,
  text,
  managerKey,
  isModal,
}) => {
  return (
    <>
      <div className={styles.element}>
        <div className={styles.checkboxName}></div>
        <div className={styles.field}>
          <FormControl
            variant="standard"
            sx={{ m: 1, width: 350, minWidth: 200 }}
          >
            <InputLabel color="warning" id="demo-simple-select-standard-label">
              {text}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={manager || ""}
              onChange={(e) => handleChangeManager(e, managerKey, isModal)}
              color="warning"
              label={text}
            >
              {managers.map((manager) => (
                <MenuItem key={manager.id} value={manager.id || ""}>
                  {manager.full_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
