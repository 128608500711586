import React, { useEffect } from "react";
import styles from "./NewInfoModal.module.scss";
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import ReusableChip from "../ReusableChip/ReusableChip";
import { GrAttachment } from "react-icons/gr";
import { useSelector } from "react-redux";

const label = { inputProps: { "aria-label": "controlled" } };

const currencies = [
  "USD",
  "EUR",
  "RUB",
  "GBP",
  "CNY",
  "KZT",
  "TRY",
  "INR",
  "JPY",
  "AED",
];

const NewInfoModal = ({
  options,
  newContract,
  handleFileChange,
  handleChangeInputs,
  triggerFileInput,
  selectedFiles,
  newContractData,
}) => {
  const handleChangeCurrency = (e) => {
    console.log(e.target.value);
  };

  const company = useSelector((state) => state.agent.company);

  return (
    <div className={styles.modalContainer}>
      {options.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles.modalElement}>
            {item.name !== "vbk" && (
              <div className={styles.name}>
                <span>{item.label}</span>
              </div>
            )}
            {item.name === "vbk" && newContractData.is_vbk && (
              <div className={styles.name}>
                <span>{item.label}</span>
              </div>
            )}
            {item.type === "Chip" && (
              <div className={styles.field}>
                <ReusableChip
                  borderColor=""
                  backgroundColor=""
                  color=""
                  label={item.text}
                  minHeight="45px"
                  minWidth="100px"
                />
              </div>
            )}
            {item.type === "Text" &&
              (item.name === "full_name" ? (
                <div className={styles.field}>{company.full_name}</div>
              ) : null)}
            {item.type === "TextField" && (
              <div className={styles.field}>
                <TextField
                  onChange={(e) => handleChangeInputs(e, item.name)}
                  label={item.text}
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      color: "#757575",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#757575",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#757575",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#757575",
                    },
                  }}
                />
              </div>
            )}
            {item.type === "Button" && item.name !== "vbk" && (
              <Button
                triggerFileInput={triggerFileInput}
                handleFileChange={handleFileChange}
                item={item}
                selectedFiles={selectedFiles}
              />
            )}

            {item.type === "Button" &&
              item.name === "vbk" &&
              newContractData.is_vbk && (
                <Button
                  triggerFileInput={triggerFileInput}
                  handleFileChange={handleFileChange}
                  item={item}
                  selectedFiles={selectedFiles}
                />
              )}

            {item.type === "Select" && (
              <div className={styles.field}>
                <Select
                  value={item.text || ""}
                  onChange={(e) => handleChangeCurrency(e)}
                >
                  {currencies.map((currency, index) => (
                    <MenuItem key={index} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            {item.type === "Checkbox" && (
              <div className={styles.field}>
                <Switch
                  {...label}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "rgba(38, 45, 137, 0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(38, 45, 137, 0.1)",
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "rgba(38, 45, 137, 0.7)",
                    },
                  }}
                  onChange={(e) => handleChangeInputs(e, item.name)}
                />
              </div>
            )}
          </div>

          {index < options.length - 1 && item.name !== "vbk" && (
            <div className={styles.line} />
          )}
          {index < options.length - 1 &&
            item.name === "vbk" &&
            newContractData.is_vbk && <div className={styles.line} />}
        </React.Fragment>
      ))}
      {newContract && (
        <div className={styles.textInfo}>
          <span>
            ! Если оплата по инвойсу – введите номер инвойса в комментарий и
            добавьте его в файлы;
          </span>
          <span>! Если нет контракта – укажите причину в комментарии;</span>
          <span>
            ! ВБК – ведомость банковского контроля. Обязательно с V разделами и
            печатью банка. Если отсутствует – укажите причину в комментарии;
          </span>
          <span>
            ! Если нет ДТ по этому контракту, товар ввозится впервые – приложите
            любые на компанию плтаельщика. Если первая отгрузка – укажите
            причину в комментарии.
          </span>
        </div>
      )}
    </div>
  );
};

const Button = ({
  triggerFileInput,
  handleFileChange,
  item,
  selectedFiles,
}) => {
  return (
    <div className={styles.field}>
      <button
        className={styles.uploadButton}
        onClick={() => triggerFileInput(item.name)}
      >
        <span>{item.text}</span>
        <div>
          <GrAttachment size={20} />
        </div>
      </button>
      <input
        id={item.name}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleFileChange(e, item.name, item.label)}
      />
      {selectedFiles[item.name]?.file && (
        <span className={styles.fileName}>
          {selectedFiles[item.name].file.name}
        </span>
      )}
    </div>
  );
};

export default NewInfoModal;
